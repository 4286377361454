exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-climate-change-graphs-atmospheric-co-2-js": () => import("./../../../src/pages/climate-change/graphs/atmosphericCo2.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-atmospheric-co-2-js" */),
  "component---src-pages-climate-change-graphs-common-js": () => import("./../../../src/pages/climate-change/graphs/common.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-common-js" */),
  "component---src-pages-climate-change-graphs-electricity-js": () => import("./../../../src/pages/climate-change/graphs/electricity.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-electricity-js" */),
  "component---src-pages-climate-change-graphs-emissions-by-country-js": () => import("./../../../src/pages/climate-change/graphs/emissionsByCountry.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-emissions-by-country-js" */),
  "component---src-pages-climate-change-graphs-food-js": () => import("./../../../src/pages/climate-change/graphs/food.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-food-js" */),
  "component---src-pages-climate-change-graphs-global-temp-js": () => import("./../../../src/pages/climate-change/graphs/globalTemp.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-global-temp-js" */),
  "component---src-pages-climate-change-graphs-man-made-emissions-js": () => import("./../../../src/pages/climate-change/graphs/manMadeEmissions.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-man-made-emissions-js" */),
  "component---src-pages-climate-change-graphs-personal-footprint-js": () => import("./../../../src/pages/climate-change/graphs/personalFootprint.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-personal-footprint-js" */),
  "component---src-pages-climate-change-graphs-relative-changes-js": () => import("./../../../src/pages/climate-change/graphs/relativeChanges.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-relative-changes-js" */),
  "component---src-pages-climate-change-graphs-temp-annomalies-js": () => import("./../../../src/pages/climate-change/graphs/tempAnnomalies.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-temp-annomalies-js" */),
  "component---src-pages-climate-change-graphs-transportation-js": () => import("./../../../src/pages/climate-change/graphs/transportation.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-transportation-js" */),
  "component---src-pages-climate-change-graphs-world-primary-energy-js": () => import("./../../../src/pages/climate-change/graphs/worldPrimaryEnergy.js" /* webpackChunkName: "component---src-pages-climate-change-graphs-world-primary-energy-js" */),
  "component---src-pages-climate-change-index-js": () => import("./../../../src/pages/climate-change/index.js" /* webpackChunkName: "component---src-pages-climate-change-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-templates-blogpost-index-js": () => import("./../../../src/templates/blogpost/index.js" /* webpackChunkName: "component---src-templates-blogpost-index-js" */)
}

